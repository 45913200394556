@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































































































































































































.drawer-container {
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
  position: relative;

  .el-form {
    display: flex;
  }

  .L {
    width: 100%;
    height: calc(100vh - 120px);
    padding: 0 0 0 20px;
    border-right: 1px solid #ededed;

    .product-info {
      margin: 20px 0;

      .img {
        width: 72px;
        height: 72px;
        position: relative;

        img {
          width: 72px;
          height: 72px;
        }

        span {
          font-size: 12px;
          position: absolute;
          top: 0;
          right: 0;
          background: #e8f4ff;
          border: 1px solid #d1e9ff;
          color: #409eff;
        }
      }

      .text {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 88%;
      }

      p {
        margin: 0;
        line-height: 24px;
      }
    }

    .num-style {
      margin-top: -15px;
    }

    p {
      text-indent: 10px;
      margin: 0 0 10px;
    }

    .base-info {
      margin-bottom: 10px;

      .name {
        padding-right: 10px;
        text-align: right;
      }

      .res {
        width: calc(100% - 160px);

        .el-tag {
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }

  .R {
    width: 50%;
    padding: 5px 20px 0 10px;
    display: none;

    p {
      margin-bottom: 5px;
      text-indent: 15px;
    }

    .box {
      display: flex;
      padding-left: 15px;

      .title {
        width: 150px;
        background: #f6f8f9;
        margin-top: 1px;
      }

      .content {
        width: calc(100% - 150px);
        overflow-x: auto;
        display: flex;

        .price-box {
          min-width: 100px;
          margin: 1px -1px 0;
        }
      }

      .empty {
        width: calc(100% - 150px);
        margin: 0 auto;
        border: 1px solid #eceff5;
      }

      ul li {
        text-indent: 10px;
        list-style: none;
        line-height: 36px;
        border: 1px solid #eceff5;
        margin-top: -1px;
        margin-right: -1px;
      }
    }
  }

  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    padding: 14px 20px;
    border-top: 1px solid #ededed;
  }

  .dialog-content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 20px;
  }
}

::v-deep {
  .el-select-dropdown {
    width: 480px;
  }
}
