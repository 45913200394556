@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-container {
  overflow-x: hidden;
  padding-bottom: 0 !important;

  .disable-color {
    color: #c0c4cc;
  }

  .inquiry-content {
    padding: 0 20px 0;

    h4 {
      font-weight: 600;
      font-size: 14px;
      margin: 20px 0 10px 0;
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .company-name {
      position: relative;

      .list {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 12px 0 #ccc;
        border: 1px solid #e4e7ed;
        background: white;
        padding: 5px 0;

        p {
          font-size: 12px;
          color: #999;
          text-align: left;
          display: flex;
          justify-content: space-between;

          i {
            font-size: 18px;
            color: #333;
            cursor: pointer;
          }
        }

        ul {
          min-height: 200px;
          max-height: 200px;
          overflow-y: auto;

          li {
            padding: 0 10px 5px;
            height: 34px;
            line-height: 34px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            span {
              width: 45%;
            }
          }

          li:hover {
            background: #f5f7fa;
          }
        }
      }
    }
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
    z-index: 2;
  }

  .wrapper-mail {
    margin-top: 10px;
  }

  .product-image {
    position: relative;
    padding: 10px 0;

    img {
      width: 66px;
      height: 66px;
      display: flex;
      margin: 0 auto;
    }

    span {
      font-size: 12px;
      position: absolute;
      top: 5px;
      right: 15px;
      background: #e8f4ff;
      border: 1px solid #d1e9ff;
      color: #409eff;
      line-height: 16px;
    }
  }

  .product-info {
    text-align: left;
    line-height: 20px;
    padding: 10px 0;
  }

  .quick-remake {
    width: 100%;
  }
}
