@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































p {
  text-indent: 15px;
  margin-bottom: 20px;
}
.num-style {
  margin-top: -10px;
}
::v-deep {
  .el-input {
    width: 300px;
  }
}
